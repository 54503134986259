
import namespaces from '@/store/namespaces';
import { AuthorizedUser } from '@/types/users';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import NoCampaigns from '@/shared-components/NoCampaigns.vue';
import SnackBar from '@/ui-components/SnackBar/SnackBar.vue';
import { storeOwnerRouteNames } from '@/route-names/store-owner';

@Component({
  name: 'DashboardPage',
  components: {
    NoCampaigns,
    SnackBar,
  },
})
export default class DashboardPage extends Vue {
  @Getter('userInfo', { namespace: namespaces.AuthModule })
  public userInfo!: AuthorizedUser;

  public campaignCreationRoute = storeOwnerRouteNames.MANAGE_MY_ADS;

  public get userName() {
    const { NAME = '' } = this.userInfo || {};
    const [firstName] = NAME.split(' ');
    return firstName;
  }
}
